/*!

 =========================================================
 * Material Kit React - v1.8.0 based on Material Kit - v2.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const lightPrimaryColor = "#999999" /* light grey */
const lightPrimaryColorRGB = "153, 153, 153"

const darkPrimaryColor = "#67696b" /* dark grey */
const darkPrimaryColorRGB = "103, 105, 107"

const primaryColor = lightPrimaryColor
const secondaryColor = "#ffffff"
const tertiaryColor = "#FFFAEF" /* peach */
const warningColor = "#da353f"
const dangerColor = "#ba222b"
const successColor = "#77ba22"
const infoColor = "#22bab1"
const dashboardColor = tertiaryColor /* peach */
const rowBgColor = "#badbf5"
const grayColor = "#e9e9e9" /** used as odd row background */

/** background colors */
const primaryBgColor = lightPrimaryColor
const secondaryBgColor = darkPrimaryColor
const tertiaryBgColor = tertiaryColor

const primaryTextColor = tertiaryBgColor /** peach */
const secondaryTextColor = "#ffffff"
const tertiaryTextColor = darkPrimaryColor

const drawerWidth = 260

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
}

/** fonts variables and methods */
const remSize = 16
const getCalcRem = pixels => {
  return pixels / remSize + "rem"
}
const containerFluid = {
  //paddingRight: "15px",
  //paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  width: "100%",
}
const container = {
  ...containerFluid,
  maxWidth: "100%",
  "@media (min-width: 1600px)": {
    maxWidth: "1600px",
  },
  "@media (max-height: 680px and min-width:768px)": {
    maxWidth: "768px",
  },
}

const appMinWidth = {
  //minWidth: "320px"
}

const appBarMinHeight = 72
const pageHeaderMinHeight = 48
const footerMinHeight = 64

const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
}

const card = {
  display: "inline-block",
  position: "relative",
  width: "100%",
  margin: "25px 0",
  boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  borderRadius: "3px",
  color: "rgba(0, 0, 0, 0.87)",
  background: secondaryTextColor,
}

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "300",
  lineHeight: "1.5em",
}

const lightPrimaryBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(" +
    lightPrimaryColorRGB +
    ", 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(" +
    lightPrimaryColorRGB +
    ", 0.2)",
}
const primaryBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(0,0,0, 0.06), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0,0,0, 0.2)",
}

const primaryBoxShadowHover = {
  boxShadow:
    "0 12px 20px -10px rgba(0,0,0, 0.14), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0,0,0, 0.2)",
}

const secondaryBoxShadow = {
  /**
  boxShadow:
    "0 12px 20px -10px rgba(" +
    secondaryColorRGB +
    ", 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(" +
    secondaryColorRGB +
    ", 0.2)"
     */
}

const darkSecondaryBoxShadow = {
  /** 
  boxShadow:
    "0 12px 20px -10px rgba(" +
    darkSecondaryColorRGB +
    ", 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(" +
    darkSecondaryColorRGB +
    ", 0.2)"
     */
}

const infoBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)",
}
const successBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)",
}
const warningBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)",
}
const dangerBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)",
}
const dashboardBoxShadow = {
  boxShadow:
    "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)",
}

const warningCardHeader = {
  color: secondaryTextColor,
  background: "linear-gradient(60deg, #ffa726, #fb8c00)",
  ...warningBoxShadow,
}
const successCardHeader = {
  color: secondaryTextColor,
  background: "linear-gradient(60deg, #66bb6a, #43a047)",
  ...successBoxShadow,
}
const dangerCardHeader = {
  color: secondaryTextColor,
  background: "linear-gradient(60deg, #ef5350, #e53935)",
  ...dangerBoxShadow,
}
const infoCardHeader = {
  color: secondaryTextColor,
  background: "linear-gradient(60deg, #26c6da, #00acc1)",
  ...infoBoxShadow,
}
const primaryCardHeader = {
  color: primaryTextColor,
  background: "transparent",
}
const dashboardCardHeader = {
  color: secondaryTextColor,
  background: "linear-gradient(60deg, #ec407a, #d81b60)",
  ...dashboardBoxShadow,
}
const cardActions = {
  margin: "0 20px 10px",
  paddingTop: "10px",
  borderTop: "1px solid #eeeeee",
  height: "auto",
  ...defaultFont,
}

const cardHeader = {
  borderTop: "2.5px solid " + primaryBgColor,
  //borderBottom: "1.5px solid " + primaryBgColor,
  display: "flex",
  flexDirection: "row",
  letterSpacing: "0.1rem !important",
  lineHeight: "42px !important",
  fontWeight: "500 !important",
  textTransform: "lowercase",
}

const bodyStyles = {
  margin: "0",
  padding: "0",
  width: "100%",
  height: "100%",
}

const defaultBoxShadow = {
  border: "0",
  borderRadius: "3px",
  boxShadow:
    "0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  padding: "10px 0",
  transition: "all 150ms ease 0s",
}

const title = {
  color: "#3C4858",
  margin: "1.75rem 0 0.875rem",
  textDecoration: "none",
  fontWeight: "700",
  fontFamily: `"Roboto Slab", "Times New Roman", serif`,
}

const cardTitle = {
  ...title,
  marginTop: ".625rem",
}

const cardLink = {
  "& + $cardLink": {
    marginLeft: "1.25rem",
  },
}

const cardSubtitle = {
  marginBottom: "0",
  marginTop: "-.375rem",
}

const defaultIconButton = {
  /*=
  justifySelf: "flex-end",
  height: "2rem",
  width: "2rem",
  "@media (min-width: 769px)": {
    height: "2.625rem",
    width: "2.625rem"
  },
  "& button": {
    backgroundColor: primaryTextColor,
    //opacity: ".8"
    "& svg": {
      fill: secondaryBgColor,
      border: "2px solid " + secondaryBgColor,
      borderRadius: "21px"
    }
  }
  */
}
const defaultXSMobileFontSize = getCalcRem(16) /**  400px and down */
const defaultMobileFontSize = getCalcRem(18) /**  sm down */
const defaultDesktopFontSize = getCalcRem(24) /** md up */

export {
  //variables
  drawerWidth,
  transition,
  container,
  containerFluid,
  appMinWidth,
  appBarMinHeight,
  pageHeaderMinHeight,
  footerMinHeight,
  boxShadow,
  card,
  defaultFont,
  primaryColor,
  darkPrimaryColor,
  darkPrimaryColorRGB,
  lightPrimaryColor,
  secondaryColor,
  tertiaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  dashboardColor,
  grayColor,
  primaryBgColor,
  secondaryBgColor,
  tertiaryBgColor,
  rowBgColor,
  primaryTextColor,
  secondaryTextColor,
  tertiaryTextColor,
  primaryBoxShadow,
  primaryBoxShadowHover,
  secondaryBoxShadow,
  darkSecondaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  dashboardBoxShadow,
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  dashboardCardHeader,
  cardActions,
  cardHeader,
  bodyStyles,
  defaultBoxShadow,
  title,
  cardTitle,
  cardLink,
  cardSubtitle,
  getCalcRem,
  defaultXSMobileFontSize,
  defaultMobileFontSize,
  defaultDesktopFontSize,
}
